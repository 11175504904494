import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { likeDislikePost, userActivity } from "../../../Reducer/api";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSaved } from "../../../Reducer/savedSlice";
import { Icon } from "@iconify/react";
import { t } from "i18next";
import {
  checkVal,
  findUploads,
  formatPrice,
  isImageOrVideo,
  makeTagText,
  renderPhoneNumber,
  setAdsArray,
} from "../../../utils/constants";
import { paths } from "../../../utils/paths";

const OwlItemSaved = ({
  responce,
  page,
  onChange,
  selectedList,
  iconText,
  checkBoxText,
  adsArray,
}) => {
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.auth.language);
  const [responces, setResponces] = useState(responce);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const rtl = lang !== "en";
  const saved = useSelector((state) => state.saved.saved);
  const [loading, setLoading] = useState(false);
  const [beingSaved, setBeingSavedId] = useState("");

  const selectBox = async (item, e) => {
    let data = selectedList;
    // const index = data.findIndex((obj) => obj._id === items._id);
    // if (index !== -1) {
    //   onChange([
    //     ...data.slice(0, index),
    //     ...data.slice(index + 1, data.length),
    //   ]);
    // } else {
    // }
    if (e) {
      onChange([...data, item]);
    } else {
      onChange(data.filter((v) => v?._id !== item?._id));
    }
  };

  const deleteSavedCar = async (adId) => {
    if (!localStorage.getItem("token")) {
      navigate("/login", {
        state: { backPage: location.pathname + location?.search },
      });
      return;
    }
    setBeingSavedId(adId);
    setLoading(true);
    let res = await likeDislikePost(adId, "remove", setLoading);
    if (res) {
      dispatch(fetchSaved());
      setResponces([]);
    }
  };
  // console.log(selectedList)
  const gotoCar = (item) => {
    // setCourierFilters(filters, "owlItemSaved");
    setAdsArray(adsArray);
    if (item?.adTypeName?.["en"] === "Sell") {
      navigate(`${paths.singleCar}?adId=${item?._id}`);
    } else {
      navigate(`${paths.singleRental}?adId=${item?._id}`);
    }
  };

  const phoneClick = () => {
    userActivity({ dealerId: responce?._id, type: "phoneView" });
  };

  return (
    <>
      {saved?.ads?.map((item, key) => {
        const selectedIds =
          selectedList?.length > 0 ? selectedList.map((v) => v?._id) : [];
        const tagText = makeTagText(item, lang);
        let firstImage =
          findUploads(item)?.[0]?.thumbnail || item?.uploads?.[0]?.s3URL;
        const type = isImageOrVideo(firstImage);
        return (
          <div class="item">
            <div class="set">
              {item?.owner?.userType !== "dealer" && (
                <p className="owl-item-badge">
                  {" "}
                  <span
                    style={{
                      borderRadius:
                        lang !== "en" ? "0px 0px 0px 5px" : "0px 0px 5px 0px",
                      fontSize: "11px",
                      backgroundColor: "#334660",
                      paddingInline: "10px",
                    }}
                    class=" text-light float-start"
                  >
                    {item?.owner?.userType === "user" ? t("Private ad") : ""}
                    {item?.owner?.userType === "brand" &&
                    item?.properties?.adLabel
                      ? t(item?.properties?.adLabel)
                      : ""}
                  </span>
                </p>
              )}
              {page == "saved" && (
                <label class="checkcontainer" style={{ zIndex: "10" }}>
                  {checkBoxText}
                  <input
                    type="checkbox"
                    onChange={(e) => selectBox(item, e.target.checked)}
                    // disabled={selectedList.length >= 4}
                    name="quality"
                    checked={selectedIds?.includes(item?._id)}
                    class="quality"
                    value="I1"
                  />
                  <span class="radiobtn"></span>
                </label>
              )}
              <div
                style={{ cursor: "pointer", borderRadius: "5px" }}
                onClick={() => gotoCar(item)}
              >
                {type === "video" ? (
                  <video
                    controls={false}
                    className="rounded-2 small-icon"
                    height={290}
                    style={{
                      objectFit: "cover",
                      marginBottom: "-7px",
                      width: "100%",
                    }}
                  >
                    <source src={firstImage || "assets/images/notFound.png"} />
                  </video>
                ) : (
                  <img
                    src={firstImage || "assets/images/notFound.png"}
                    alt="blog1"
                    height={290}
                    className={`small-icon`}
                    style={{ objectFit: "cover" }}
                  />
                )}
                {/* <SearchCrousel images={images} id={item?._id}/> */}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => gotoCar(item)}>
                <h3>
                  <h3
                    className="smallScreenFont"
                    style={{ marginBlock: "0px" }}
                  >
                    {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                    {t(item?.properties?.basicDetails?.model?.[lang])}
                  </h3>
                  <span className="">
                    <img
                      src={"../assets/images/red_fill.png"}
                      id={`unfill${item.id + page}`}
                      align="icon"
                    />
                    {iconText}
                  </span>
                </h3>
                <p
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    height: "45px",
                  }}
                >
                  {tagText}
                </p>
                <br />
                {/* <span class="title match" data-mh="title">
                  {item?.entity?.properties?.specifications?.fuelType?.[lang]}
                </span> */}
              </div>
              <div
                className="d-flex justify-content-between cardSmallSaved"
                style={{ paddingBottom: "5px" }}
              >
                <div
                  className="fw-semibold smallScreenFont"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    height: "45px",
                  }}
                >
                  {checkVal(item?.askPrice) ? (
                    item?.adTypeName?.["en"] === "Sell" ? (
                      formatPrice(lang, item?.askPrice, item?.dollorAskPrice)
                    ) : (
                      formatPrice(
                        lang,
                        item?.askPrice,
                        item?.dollorAskPrice
                      ).concat("/Day")
                    )
                  ) : (
                    <>
                      {renderPhoneNumber(item?.owner, ({ phone }) => (
                        <a
                          href={`tel:${phone}`}
                          className="d-flex align-items-center gap-1"
                          onClick={(e) => phoneClick()}
                          style={{ color: `var(--text-black-1-2)` }}
                        >
                          {/* <img height={25} className="w-100" src="/assets/images/call.png" alt="img" /> */}
                          <Icon
                            icon="mi:call"
                            className={`${rtl ? "imgRTL" : ""}`}
                            style={{ color: `var(--text-black-1-2)` }}
                          />
                          {t("Contact seller")}
                        </a>
                      ))}
                    </>
                  )}
                </div>
                <div>
                  {loading && beingSaved === item?._id ? (
                    <button
                      class="btn btn-sm btn-light"
                      style={{
                        backgroundColor: "transparent",
                        border: "unset",
                        height: 20,
                      }}
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden" role="status">
                        Loading...
                      </span>
                    </button>
                  ) : (
                    <Icon
                      icon="ic:baseline-delete"
                      width="27"
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={() => !loading && deleteSavedCar(item._id)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default OwlItemSaved;
